import React from "react"
import { graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Link from "../components/link"
import bgImage from "../images/homeBg.svg"
import heroImage from "../images/hero-3.jpg"
import bookImage from "../images/basket_san_dona_pt2.jpeg"
import Badge from "@material-ui/core/Badge"
import SimpleCard from "../components/simpleCard"
import Typography from "@material-ui/core/Typography"
import Sezione from "../components/sezione"

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: "-19px",
    backgroundImage: "url(" + bgImage + ")",
    backgroundPosition: "0px 0px",
    backgroundSize: "100%",
    backgroundRepeat: "repeat-x",
    [theme.breakpoints.down("md")]: {
      backgroundSize: "auto",
    },
  },
  hero: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "18px",
    paddingTop: "100px",
    marginBottom: "36px",
    width: "100%",
    [theme.breakpoints.down("md")]: {
      marginRight: "auto",
      marginLeft: "auto",
      paddingLeft: "auto",
      paddingRight: "auto",
    },
  },
  text: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "3.5rem",
    fontWeight: 900,
    color: "#274dd1",
    marginTop: "40px",
    [theme.breakpoints.down("md")]: {
      fontSize: "38px",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "-10px",
    },
  },
  heroImage: {
    paddingLeft: "0px",
    flexGrow: 1,
    flexShrink: 1,
    flexBasis: "0%",
    borderRadius: "12px",
    maxWidth: "600px",
    [theme.breakpoints.only("md")]: {
      width: "25%",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      borderWidth: "5px",
      marginLeft: "auto",
      marginTop: "-10px",
    },
  },
  book: {
    marginTop: "40px",
    [theme.breakpoints.up("lg")]: {
      marginTop: "60px",
    },
    [theme.breakpoints.up("xl")]: {
      marginTop: "200px",
    },
  },
  bookImage: {
    maxHeight: "70vh",
    borderRadius: "12px",
    [theme.breakpoints.down("md")]: {
      marginLeft: "10%",
      marginRight: "10%",
    },
    [theme.breakpoints.down("sm")]: {
      maxHeight: "50vh",
      maxWidth: "35vw",
      marginLeft: "10%",
      marginRight: "10%",
    },
  },
  bookTextPrimary: {
    color: "#d3a20f",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "1.6rem",
  },
  bookTextSecondary: {
    color: "#d3a20f",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "1rem",
  },
  bookButton: {
    backgroundColor: "#f2545b",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
  mouseScroll: {
    display: "flex",
    marginTop: "23px",
    marginRight: "auto",
    marginLeft: "auto",
    paddingRight: "auto",
    paddingLeft: "auto",
    justifyContent: "center",
    alignItems: "center",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  svg: {
    width: "100%",
    height: "200px",
    [theme.breakpoints.only("xs")]: {
      height: "100px",
      marginBottom: 20,
    },
  },
  sezione: {
    marginTop: 50,
    [theme.breakpoints.only("xs")]: {
      marginLeft: 50,
      marginRight: 50,
    },
  },
  titoloSezione: {
    color: "#f2545b",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 800,
    fontSize: "38px",
  },
  cardButton: {
    backgroundColor: "#f2545b",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
  button: {
    backgroundColor: "#f2545b",
    color: "#fff",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "20px",
    fontWeight: 900,
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
}))

const Home = ({ data }) => {
  const classes = useStyles()

  // sezioni is data for pages sections
  const sezioni = [
    {
      "data": data.squadre,
      "title": "Squadre",
      "type": "squadre",
      "slug": "squadre/",
      "buttonString": "Clicca il bottone 'Squadre' per andare a vedere tutte le Squadre"
    },
    {
      "data": data.articoli,
      "title": "Articoli",
      "type": "articoli",
      "slug": "articoli/",
      "buttonString": "Clicca il bottone 'Articoli' per andare a vedere tutta gli Articoli"
    },
    {
      "data": data.storia,
      "title": "Storia",
      "type": "storia",
      "slug": "storia/",
      "buttonString": "Clicca il bottone 'Storia' per andare a vedere tutta la Storia"
    },
    {
      "data": data.galleria,
      "title": "Galleria",
      "type": "galleria",
      "slug": "galleria/",
      "buttonString": "Clicca il bottone 'Galleria' per andare a vedere tutte le foto e i video"
    },
    {
      "data": data.manifesti,
      "title": "Manifesti",
      "type": "manifesti",
      "slug": "manifesti/",
      "buttonString": "Clicca il bottone 'Storia' per andare a vedere tutta la Storia"
    },
  ]

  return (
    <Layout title="Home">
      <div className={classes.root}>
        <section className={classes.hero}>
          <Container>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <h1 className={classes.text}>
                  La società <br /> che ha PORTATO <br /> il BASKET a <br /> San
                  Donà di Piave
                </h1>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img
                  src={heroImage}
                  className={classes.heroImage}
                  alt="Jesolo San Donà Basket, Vittoria Coppa Italia Serie B al Palalido di Milano il 20 marzo 2008"
                />
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.libro}>
          <Container>
            <Grid container className={classes.book}>
              <Grid item xs={12}>
                <h2 className={classes.bookTextPrimary}>Compra il Libro!!!</h2>
              </Grid>
              <Grid item xs={12} sm={6}>
                <img
                  src={bookImage}
                  className={classes.bookImage}
                  alt="Il basket a San Donà e dintorni 1929-1978 Origini Storia Documenti di Giambattista Ferrari"
                />
              </Grid>
              <Grid item xs={12} sm={6} className={classes.bookText}>
                <strong className={classes.bookTextPrimary}>
                  IL BASKET A SAN DONÀ e dintorni
                </strong>
                <p className={classes.bookTextSecondary}>
                  1929-1978
                  <br />
                  Origini Storia Documenti
                  <br />
                  di Giambattista Ferrari
                </p>
                <p>
                  Si terrà la presentazione del libro il giorno
                  <strong> Venerdì 10 giugno 2022, ore 18.30 </strong>
                  presso la<strong> BIBLIOTECA CIVICA di JESOLO</strong>
                  <span> in Sala Gelli</span>.
                </p>
                <p>
                  "Se non c'è qualcuno che raccoglie una testimonianza, qualcuno
                  che ne scrive, qualcuno che fa una foto, qualcuno che ne
                  lascia una traccia in un libro, è come se i fatti non fossero
                  mai avvenuti! Perchè la storia esiste solo se qualcuno la
                  racconta"<span>Roberto Pellizzaro</span>
                </p>
                <Button className={classes.bookButton}>
                  <Link to="libro/">MODALITÀ ACQUISTO LIBRO</Link>
                </Button>
                <br />
                <br />
                <Link to="libro/#content">
                  <Typography color="secondary">
                    Clicca qui per visionare le espansioni del libro
                  </Typography>
                </Link>
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.sezione}>
          <Container>
            <Grid container spacing={5}>
              <Grid item xs={12}>
                <h2 className={classes.titoloSezione}>In primo piano</h2>
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ marginTop: "-30px" }}>
                <Badge color="primary" badgeContent="Nuovo">
                  <SimpleCard
                    type="articoli"
                    title={data.articoli.nodes[0].titolo}
                    descrizione={data.articoli.nodes[0].descrizione}
                    slug={"/articoli/" + data.articoli.nodes[0].slug}
                    image={data.articoli.nodes[0].foto}
                  />
                </Badge>
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ marginTop: "-30px" }}>
                <Badge color="primary" badgeContent="Nuovo">
                  <SimpleCard
                    type="articoli"
                    title={data.articoli.nodes[1].titolo}
                    descrizione={data.articoli.nodes[1].descrizione}
                    slug={"/articoli/" + data.articoli.nodes[1].slug}
                    image={data.articoli.nodes[1].foto}
                  />
                </Badge>
              </Grid>
              <Grid item xs={12} sm={6} md={4} style={{ marginTop: "-30px" }}>
                <Badge color="primary" badgeContent="Nuovo">
                  <SimpleCard
                    type="articoli"
                    title={data.articoli.nodes[2].titolo}
                    descrizione={data.articoli.nodes[2].descrizione}
                    slug={"/articoli/" + data.articoli.nodes[2].slug}
                    image={data.articoli.nodes[2].foto}
                  />
                </Badge>
              </Grid>
            </Grid>
          </Container>
        </section>
        <div>
          {sezioni.map((obj, index) => (
            <Sezione
              key={index}
              data={obj.data}
              title={obj.title}
              type={obj.type}
              slug={obj.slug}
              buttonString={obj.buttonString}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default Home

export const query = graphql`
  {
    squadre: allContentfulSquadra(
      limit: 3
      sort: { fields: createdAt, order: DESC }
      filter: { node_locale: { eq: "it-IT" } }
    ) {
      nodes {
        nome
        slug
        foto {
          title
          description
          fluid {
            src
          }
          fixed(quality: 100) {
            src
          }
        }
      }
    }
    articoli: allContentfulArticolo(
      filter: { node_locale: { eq: "it-IT" } }
      limit: 3
      sort: { fields: updatedAt, order: DESC }
    ) {
      nodes {
        titolo
        slug
        data
        descrizione
        foto {
          title
          description
          fluid {
            src
          }
        }
      }
    }
    storia: allContentfulPeriodo(
      filter: { node_locale: { eq: "it-IT" } }
      limit: 3
      sort: { fields: createdAt, order: DESC }
    ) {
      nodes {
        nome
      }
    }
    galleria: allContentfulMedia(
      filter: {
        titolo: {regex: "/^((?!Foto di|Video|Stats|Rassegna|Manifesti).)*$/gm"},
        node_locale: {eq: "it-IT"},
        media: {file: {contentType: {ne: "application/pdf"}}}
      },
      limit: 3
    ) {
      nodes {
        titolo
        descrizione {
          descrizione
        }
        media {
          title
          description
          fluid {
            src
          }
          file {
            contentType
          }
        }
      }
    }
    manifesti: allContentfulManifesto(
      filter: { node_locale: { eq: "it-IT" } }
      sort: { fields: createdAt, order: DESC }
      limit: 3
    ) {
      nodes {
        titolo
        slug
        descrizione
        media {
          titolo
          descrizione {
            descrizione
          }
          media {
            fluid {
              src
            }
          }
        }
      }
    }
  }
`
