import React from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Link from "./link"
import Typography from "@material-ui/core/Typography"
import defaultImage from "../images/defaultTeam.png"

const SimpleCard = ({ title, descrizione, data, image, slug, type }) => {
  var textButton = ""
  if (type === "squadre") {
    textButton = "visualizza"
    var anno = parseInt(title.slice(-4))
    var annoP = (anno + 1).toString().slice(-2)
    descrizione = "Anno Sportivo " + anno.toString() + "/" + annoP
  } else if (type === "articoli") {
    textButton = "leggi"
  } else if (type === "storia") {
    textButton = "leggi"
  } else if (type === "manifesti") {
    textButton = "leggi"
  } else if (type === "galleria") {
    textButton = "visualizza"
  }

  return (
    <Grid
      container
      spacing={0}
      style={{
        "&:hover": {
          border: "0.5px solid hsla(0, 0%, 0%, 0.3)",
          borderRadius: 20,
        },
      }}
    >
      <Grid item xs={12}>
        <Link to={slug}>
          {image !== null && image !== undefined && image.fluid !== null ? (
            <img
              src={image.fluid.src}
              alt={image.description}
              title={image.title}
              style={{
                borderTopLeftRadius: "20px",
                borderTopRightRadius: "20px",
              }}
            />
          ) : (
            <img src={defaultImage} alt="default " />
          )}
        </Link>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ paddingLeft: 10, paddingRight: 10, marginTop: "-20px" }}
      >
        <Link to={slug}>
          <Typography style={{ marginBottom: "1.4rem" }} color="secondary">
            {title !== null ? title : ""}
          </Typography>
        </Link>
      </Grid>
      {type === "articoli" || type === "manifesti" ? (
        <>
          <Grid item xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
            {descrizione !== null && descrizione !== undefined ? (
              <p
                style={{
                  fontFamily: "Montserrat",
                  fontDisplay: "swap",
                  fontSize: 15,
                  marginTop: -20,
                }}
              >
                {descrizione.length > 50
                  ? descrizione.substring(0, 50) + "..."
                  : descrizione}
              </p>
            ) : (
              ""
            )}
          </Grid>
          <Grid item xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
            {data}
          </Grid>
        </>
      ) : type === "squadre" ? (
        <h5
          style={{
            fontFamily: "Montserrat",
            fontDisplay: "swap",
            marginTop: -20,
            paddingLeft: 10,
            paddingRight: 10,
          }}
        >
          {descrizione}
        </h5>
      ) : (
        <></>
      )}
      <Grid
        item
        xs={12}
        style={{ paddingLeft: 10, paddingRight: 10, marginBottom: 20 }}
      >
        <Button href={slug} size="small" variant="outlined" color="secondary">
          {textButton}
        </Button>
      </Grid>
    </Grid>
  )
}

export default SimpleCard
