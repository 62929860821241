import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Link from "./link"
import SimpleCard from "./simpleCard"

const useStyles = makeStyles(theme => ({
  sezione: {
    marginTop: 50,
    [theme.breakpoints.only("xs")]: {
      marginLeft: 50,
      marginRight: 50,
    },
  },
  titoloSezione: {
    color: "#f2545b",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 800,
    fontSize: "38px",
  },
  button: {
    backgroundColor: "#f2545b",
    color: "#fff",
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "20px",
    fontWeight: 900,
    "&:hover": {
      backgroundColor: "#a93a3f",
    },
  },
}))

const Sezione = ({ title, type, data, buttonString, slug }) => {
  const classes = useStyles()

  return (
    <section className={classes.sezione}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <h2 className={classes.titoloSezione}>{title}</h2>
          </Grid>
          {data.nodes.map((card, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={3}
              key={index}
              style={{ marginTop: "-30px" }}
            >
              <SimpleCard
                type={type}
                title={type === 'storia' ? "Periodo "+card.nome : type === 'squadre' ? card.nome : card.titolo}
                descrizione={type === 'galleria' ? card.descrizione.descrizione : card.descrizione}
                slug={type === 'storia' || type === 'galleria' ? "/"+slug : "/" + slug + card.slug}
                image={type === 'galleria' ? card.media : card.foto}
              />
            </Grid>
          ))}
          <Grid item xs={12} sm={6} md={3}>
            <p>
              {buttonString}
            </p>
            <Button className={classes.button}>
              <Link to={slug}>{title}</Link>
            </Button>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default Sezione
